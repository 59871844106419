module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/app/node_modules/gatsby-theme-docz/src/base/Layout.js"},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/app/packages/docs/.docz"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{},"src":"./src","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":["Index","Api"],"mdPlugins":[],"hastPlugins":[],"ignore":[],"typescript":false,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"config":"doczrc.js","root":"/app/packages/docs/.docz","base":"/","source":"./","gatsby-root":null,"files":"**/*.{md,markdown,mdx}","public":"./public","dest":"./dist","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","title":"Plugins.dev","description":"Plugins.dev documentation - enable an ecosystem of 3rd-party plugins for your app in 15 minutes","host":"0.0.0.0","port":9000,"p":3000,"separator":"-","paths":{"root":"/app/packages/docs","templates":"/app/node_modules/docz-core/dist/templates","docz":"/app/packages/docs/.docz","cache":"/app/packages/docs/.docz/.cache","app":"/app/packages/docs/.docz/app","appPackageJson":"/app/packages/docs/package.json","appTsConfig":"/app/packages/docs/tsconfig.json","gatsbyConfig":"/app/packages/docs/gatsby-config.js","gatsbyBrowser":"/app/packages/docs/gatsby-browser.js","gatsbyNode":"/app/packages/docs/gatsby-node.js","gatsbySSR":"/app/packages/docs/gatsby-ssr.js","importsJs":"/app/packages/docs/.docz/app/imports.js","rootJs":"/app/packages/docs/.docz/app/root.jsx","indexJs":"/app/packages/docs/.docz/app/index.jsx","indexHtml":"/app/packages/docs/.docz/app/index.html","db":"/app/packages/docs/.docz/app/db.json"}},
    }]
